import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AV from '../views/AVHome.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'AV',
    component:AV
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
